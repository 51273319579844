<template>
  <div>
    <b-card border-variant="danger" v-if="errore_caricamento">
      <b-row class="match-height">
        <b-col
          md="8"
        >
          <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
          <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
        </b-col>
        <b-col 
          md="4"
          class="text-center"
        >
          <b-button
          variant="primary"
          :to="{ name: 'crm-customer-index' }"
          >
              <feather-icon
                  icon="CornerUpLeftIcon"
                  size="16"
                  class="mr-1"
              />Torna ai Clienti
          </b-button>
        </b-col>
      </b-row>
        
    </b-card>
    
    <div class="text-center my-3" v-if="Caricato == false">
        <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem;"
            class="mr-1"
            label="loading"
        />
    </div>

    <div v-if="Caricato" class="position-relative">
      <div class="mio_btn_float">
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon rounded-circle"
            id="`sidebar-azioni`"
            @click="sidebarActionShow(campiform.customer.id, campiform.billing.company_name, campiform.user.name+' '+campiform.user.surname, campiform.user.email, campiform.user.mobile_phone, campiform.user.phone, campiform.customer.source.text, campiform.billing.vat_number, campiform.customer.note, campiform.customer.rating, campiform.customer.status.value)"
        >
          <feather-icon icon="ArrowRightIcon" />
        </b-button>
      </div>
        <b-card>
          <b-card-body class="px-0 py-0">
              <b-tabs pills>
                <b-tab active>
                  <template #title>
                      <feather-icon
                      icon="UserIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                      />
                      <span class="d-none d-sm-inline">Anagrafica</span>
                  </template>
                  <b-card bg-variant="Default">
                    <b-row class="match-height">
                      <b-col md="8" lg="9">
                        
                        <b-card nobody border-variant="secondary" class="p-0">
                          <b-card-body class="p-0">
                            <b-card-title>Organizzazione</b-card-title>

                            <b-row>
                              <b-col md="6" lg="4">
                                <showdata field_name="Ragione Sociale" :field_value="campiform.billing.company_name" />
                              </b-col>
                              <b-col md="6" lg="4">
                                <showdata field_name="Telefono" :field_value="campiform.user.phone" />
                              </b-col>
                              <b-col md="6" lg="4">
                                <showdata field_name="Sito web" :field_value="campiform.user.website" />
                              </b-col>
                              <b-col md="6" lg="4">
                                <dl>
                                  <dt class="text-muted">Valutazione</dt>
                                  <dd>
                                    <b-badge :variant="statusVariantEvaluation(campiform.customer.evaluation.value)">
                                      {{ campiform.customer.evaluation.text }}
                                    </b-badge>
                                  </dd>
                                </dl>

                              </b-col>
                              <b-col md="6" lg="4">
                                <showdata field_name="Rating" :field_value="campiform.customer.rating" />
                              </b-col>
                              <b-col md="6" lg="4">
                                <dl>
                                  <dt class="text-muted">Stato</dt>
                                  <dd>
                                    <b-badge :variant="statusVariant(campiform.customer.status.value)">
                                      {{ campiform.customer.status.text }}
                                    </b-badge>
                                  </dd>
                                </dl>

                              </b-col>
                              <b-col md="6" lg="4">
                                <showdata field_name="Reseller" :field_value="campiform.customer.reseller_name" />
                              </b-col>
                              <b-col md="6" lg="4">
                                <showdata field_name="Assistente" :field_value="campiform.customer.assistant_name" />
                              </b-col>

                            </b-row>

                          </b-card-body>
                        </b-card>
                      </b-col>
                      <b-col md="4" lg="3" class="px-0">
                        <b-card nobody border-variant="secondary" class="p-0 assistenti-card">

                          <b-card-body class="p-0">
                            <b-card-title>Persone</b-card-title>

                              <div class="puntini-nuovo">
                                <feather-icon icon="PlusIcon" />
                              </div>

                              <swiper
                                :options="swiperOptions"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              >
                              <swiper-slide
                                  key="principale"
                                  class="p-0 pb-2"
                                >
                                  <p class="mb-05 d-flex"><span class="font-weight-bold spacer-icona">
                                    <feather-icon icon="UserIcon" /></span> <span class="truncate">{{ campiform.user.name }} {{ campiform.user.surname }}</span>
                                  </p>
                                  <p class="mb-05"><span class="font-weight-bold spacer-icona"><feather-icon icon="PhoneIcon" /></span> {{ campiform.user.mobile_phone }}</p>
                                  <p class="mb-05"><span class="font-weight-bold spacer-icona"><feather-icon icon="MailIcon" /></span> {{ campiform.user.email }}</p>
                                  <p class="mb-0"><span class="font-weight-bold spacer-icona"><feather-icon icon="FlagIcon" /></span> Utente principale</p>
                                </swiper-slide>

                                <!-- foreach per assistensti -->

                              </swiper>

                              <div
                                slot="pagination"
                                class="swiper-pagination"
                              />

                          </b-card-body>
                        </b-card>
                      </b-col>
                    </b-row>

                    </b-card>
                  </b-tab>

                  <b-tab>
                    <template #title>
                        <feather-icon
                        icon="BriefcaseIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">Fatturazione</span>
                    </template>
                    <b-card bg-variant="Default">
                      <b-row class="match-height">
                        <b-col sm="12" md="6">
                          <b-card nobody border-variant="secondary" class="p-0">
                            <b-card-body class="p-0">
                              <b-card-title>Sede legale</b-card-title>

                              <b-row>
                                <b-col md="8">
                                  <showdata field_name="Indirizzo" :field_value="campiform.billing.address" />
                                </b-col>
                                <b-col md="6" lg="4">
                                  <showdata field_name="Cap" :field_value="campiform.billing.zip" />
                                </b-col>
                                <b-col md="6" lg="4">
                                  <showdata field_name="Città" :field_value="campiform.billing.city" />
                                </b-col>
                                <b-col md="6" lg="4">
                                  <div v-if="campiform.billing.province_name == 'Estera'">
                                      <showdata field_name="Provincia" :field_value="campiform.billing.province_foreign" />
                                    </div>
                                    <div v-else>
                                      <showdata field_name="Provincia" :field_value="campiform.billing.province_name" />
                                    </div>
                                </b-col>
                                <b-col md="6" lg="4">
                                  <showdata field_name="Nazione" :field_value="campiform.billing.country_name" />
                                </b-col>

                              </b-row>

                            </b-card-body>
                          </b-card>
                        </b-col>
                        <b-col sm="12" md="6">
                          <b-card nobody border-variant="secondary" class="p-0">
                            <b-card-body class="p-0">
                              <b-card-title>Dati fiscali</b-card-title>

                              <b-row>
                                <b-col md="6" lg="4">
                                  <showdata field_name="Partita IVA" :field_value="campiform.billing.vat_number" />
                                </b-col>
                                <b-col md="6" lg="4">
                                  <showdata field_name="Codice fiscale" :field_value="campiform.billing.fiscal_code" />
                                </b-col>
                                <b-col md="6" lg="4">
                                  <showdata field_name="Codice SDI" :field_value="campiform.billing.dest_code" />
                                </b-col>
                                <b-col md="6" lg="4">
                                  <showdata field_name="Pec" :field_value="campiform.billing.pec" />
                                </b-col>
                                <b-col md="6" lg="4">
                                  <showdata field_name="Regime IVA" :field_value="campiform.billing.vat_scheme_name" />
                                </b-col>
                                <b-col md="6" lg="4">
                                  <showdata field_name="Split Payment" :field_value="campiform.billing.split_payment" />
                                </b-col>
                              </b-row>

                            </b-card-body>
                          </b-card>
                        </b-col>
                      </b-row>
                    
                    </b-card>
                  </b-tab>
                  
                  <b-tab>
                    <template #title>
                        <feather-icon
                        icon="FileTextIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">Amministrazione</span>
                    </template>
                    <b-card bg-variant="Default">
                      <b-row class="match-height">
                        <b-col sm="12" md="4">
                          <b-card nobody border-variant="secondary" class="p-0">
                            <b-card-body class="p-0">
                              <b-card-title>Condizioni</b-card-title>

                              <b-row>
                                <b-col md="12">
                                  <showdata field_name="Contratto" field_value="Digitale" />
                                </b-col>
                                <b-col md="12">
                                  <showdata field_name="Pagamenti accettati" :field_value="formattazioneArray(campiform.customer.payment)" />
                                </b-col>
                                <b-col md="6" lg="6">
                                  <showdata field_name="Metodo di pagamento" :field_value="campiform.billing.payment_method" />
                                </b-col> 
                              </b-row>
                              
                            </b-card-body>
                          </b-card>

                        </b-col>
                        <b-col sm="12" md="4">
                          <b-card nobody border-variant="secondary" class="p-0">
                            <b-card-body class="p-0">
                              <b-card-title>Collegamenti</b-card-title>
                              
                              <b-row>
                                <b-col md="12">
                                  <dl>
                                    <dt class="text-muted">ID Fatture in Cloud</dt>
                                    <dd v-if="this.campiform.billing.id_fic">
                                      <a :href="campiform.billing.url_analisi_cliente_fic" target="_blank">{{ this.campiform.billing.id_fic }}</a>
                                      <b-button
                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        variant="outline-primary"
                                        :disabled="action_press"
                                        class="btn-icon ml-2"
                                        size="sm"
                                        @click="forzaSyncFIC(campiform.customer.id)"
                                      >
                                        <div v-if="action_press"><b-spinner small class="ml-1" /></div>
                                        <div v-else><feather-icon icon="RefreshCwIcon" /></div> 
                                      </b-button>
                                    </dd>
                                    <dd v-else>
                                      N.D.
                                      <b-button
                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        variant="outline-primary"
                                        :disabled="action_press"
                                        class="btn-icon ml-2"
                                        size="sm"
                                        @click="forzaCreateSyncFIC(campiform.customer.id)"
                                      >
                                        <div v-if="action_press"><b-spinner small class="ml-1" /></div>
                                        <div v-else><feather-icon icon="ArrowUpRightIcon" /></div> 
                                      </b-button>
                                    </dd>
                                  </dl>
                                </b-col>
                                
                                <b-col md="12">
                                  <dl>
                                    <dt class="text-muted">ID Stripe</dt>
                                    <dd>
                                      <a @click="apriSchedaClienteSuStripe(campiform.user.stripe_id)">{{ campiform.user.stripe_id }}</a>
                                    </dd>
                                  </dl>

                                </b-col>
                              </b-row>

                            </b-card-body>
                          </b-card>

                        </b-col>
                        <b-col sm="12" md="4">
                          <b-card nobody border-variant="secondary" class="p-0">
                            <b-card-body class="p-0">
                              <b-card-title>Note fatture</b-card-title>
                              
                              <b-row>
                                <b-col md="12">
                                  <showdata field_name="XML" :field_value="campiform.billing.note_xml" />
                                </b-col>
                              </b-row>

                            </b-card-body>
                          </b-card>

                        </b-col>

                      </b-row>

                    </b-card>
                  </b-tab>

                  <b-tab>
                    <template #title>
                        <feather-icon
                        icon="PackageIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">CCIAA</span>
                    </template>
                    <b-card bg-variant="Default">
                      <b-row class="match-height" v-if="campiform.openapi.scaricato === true">
                        <b-col md="8" lg="9">
                          
                          <b-card nobody border-variant="secondary" class="p-0">
                            <b-card-body class="p-0">
                              <b-card-title>Visura</b-card-title>

                              <b-row>
                                <b-col md="6" lg="4">
                                  <showdata field_name="Stato attività" :field_value="campiform.openapi.base.stato_attivita" />
                                </b-col>
                                <b-col md="6" lg="4">
                                  <showdata field_name="Rea" :field_value="campiform.openapi.base.rea" />
                                </b-col>
                                <b-col md="6" lg="4">
                                  <showdata field_name="Camera di commercio" :field_value="campiform.openapi.base.cciaa" />
                                </b-col>
                                <b-col md="6" lg="4">
                                  <showdata field_name="Codice ateco" :field_value="campiform.openapi.base.codice_ateco" />
                                </b-col>
                                <b-col md="6" lg="4">
                                  <showdata field_name="Forma giuridica" :field_value="campiform.openapi.base.codice_natura_giuridica" />
                                </b-col>
                                <b-col md="6" lg="4">
                                  <showdata field_name="Rappresentante legale" :field_value="campiform.billing.legal_representative" />
                                </b-col>
                              </b-row>

                            </b-card-body>
                          </b-card>
                        </b-col>
                        <b-col md="4" lg="3" class="px-0">
                          <b-card nobody border-variant="secondary" class="p-0">

                            <b-card-body class="p-0">
                              <b-card-title>Descrizione</b-card-title>

                              <b-row>
                                <b-col>
                                  <showdata field_name="Codice ateco" :field_value="campiform.openapi.base.descrizione_ateco" />
                                </b-col>
                              </b-row> 

                            </b-card-body>
                          </b-card>
                        </b-col>
                      </b-row>
                      
                    </b-card>
                  </b-tab>

                  <b-tab>
                    <template #title>
                        <feather-icon
                        icon="BarChartIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">Bilanci</span>
                    </template>
                    <b-card bg-variant="Default">
                      <b-card bg-variant="Default">
                        <div v-if="campiform.openapi.scaricato === true">
                          <b-table responsive="sm" :items="campiform.openapi.bilanci"/>
                        </div>
                        <div v-else>
                          <b-card border-variant="primary">
                            <b-card-text>Bilanci non presenti al momento</b-card-text>
                          </b-card>
                        </div>
                      </b-card>
                     
                    </b-card>
                  </b-tab>
                  
                  <b-tab>
                    <template #title>
                        <feather-icon
                        icon="LinkIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">Tracks</span>
                    </template>
                    <b-card bg-variant="Default">
                      <b-row class="match-height">
                        <b-col md="4">
                          <b-card nobody border-variant="secondary" class="p-0">
                            <b-card-body class="p-0">
                              <b-card-title>Provenienza</b-card-title>

                              <b-row>
                                <b-col>
                                  <dl>
                                    <dt class="text-muted">Origine</dt>
                                    <dd class="capitalize-first-word"><b-badge :variant="sourceVariant(campiform.customer.source.text)">{{ campiform.customer.source.text }}</b-badge></dd>
                                  </dl>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col>
                                  <dl>
                                    <dt class="text-muted">Sezione</dt>
                                    <dd v-if="campiform.customer.section" class="capitalize-first-word">{{ campiform.customer.section }}</dd>
                                    <dd v-else>-</dd>
                                  </dl>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col>
                                  <showdata field_name="Path" :field_value="campiform.customer.path" />
                                </b-col>
                              </b-row>

                              <b-row>
                                <b-col v-if="(campiform.customer.user_agent != null && campiform.customer.ip != null)">
                                  <strong class="text-muted">Firma digitale</strong>
                                  <div class="logs-colonna">
                                    {{ formattazioneDataOra(campiform.customer.created_at, 'it') }} - Accettazione termini e condizioni di servizio<br />
                                    {{ formattazioneDataOra(campiform.customer.created_at, 'it') }} - Accettazione privacy policy<br />
                                    {{ formattazioneDataOra(campiform.customer.created_at, 'it') }} - Art. 1342 - 1343<br />
                                    {{ formattazioneDataOra(campiform.customer.created_at, 'it') }} - Accettazione Modalità di Pagamento<br />
                                    {{ formattazioneDataOra(campiform.customer.created_at, 'it') }} - {{ campiform.customer.user_agent }}<br />
                                    {{ formattazioneDataOra(campiform.customer.created_at, 'it') }} - {{ campiform.customer.ip }}
                                  </div>
                                </b-col>
                                <b-col v-else>
                                  <showdata field_name="Logs e firma digitale" field_value='mediante "Origine Lead" - inserimento da CRM del Lead' />
                                </b-col>
                              </b-row>

                            </b-card-body>
                          </b-card>

                        </b-col>
                        <b-col md="4">
                          <b-card nobody border-variant="secondary" class="p-0">
                            <b-card-body class="p-0">
                              <b-card-title>Accettazioni</b-card-title>

                              <b-row>
                                <b-col>
                                  <showdata field_name="Termini e condizioni" field_value="Si" />
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col>
                                  <showdata field_name="Informativa privacy" field_value="Si" />
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col>
                                  <showdata field_name="Newsletter" :field_value="campiform.user.newsletter" />
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col>
                                  <showdata field_name="Codice OTP:" :field_value="campiform.user.otp" />
                                </b-col>
                              </b-row>
                            </b-card-body>
                          </b-card>
                          
                        </b-col>
                        <b-col md="4">
                          <b-card nobody border-variant="secondary" class="p-0">
                            <b-card-body class="p-0">
                              <b-card-title>Date</b-card-title>

                              <b-row>
                                <b-col>
                                  <showdata field_name="Registrazione" :field_value="formattazioneDataOra(campiform.customer.lead_registered_at, 'it')" />
                                </b-col>    
                              </b-row>
                              <b-row>                    
                                <b-col>
                                  <showdata field_name="Conversione" :field_value="formattazioneDataOra(campiform.customer.created_at, 'it')" />
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col>
                                  <showdata field_name="Ultimo accesso" :field_value="formattazioneDataOra(campiform.user.last_access, 'it')" />
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col>
                                  <showdata field_name="Giorni conversione" :field_value="formattazioneDataGiorniConversione(campiform.customer.lead_registered_at, campiform.customer.converted_at, 'it')" />
                                </b-col>
                              </b-row>
                              
                            </b-card-body>
                          </b-card>
                          
                        </b-col>
                      </b-row>


                      

                    </b-card>
                  </b-tab>

                  <b-tab>
                    <template #title>
                      <feather-icon
                        icon="ClipboardIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span class="d-none d-sm-inline">Commenti <small v-if="n_commenti > 0">({{ n_commenti }})</small></span>
                    </template>
                    <b-card bg-variant="Default">
                      <b-row>
                        <b-col md="12" lg="6">
                          <div class="text-left">
                            <b-button
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              variant="outline-primary"
                              class="mb-1"
                              @click="sidebarCommento('Nuovo Commento','','')"
                            >
                              <feather-icon icon="PlusIcon" /> Inserisci Commento
                            </b-button>
                          </div>

                          <div class="comments-list">
                            <vue-perfect-scrollbar
                              class="scroll-area"
                              :settings="perfectScrollbarSettings"
                            >
                            <ul class="list-unstyled">
                              <li v-for="riga in list_comments"
                                :key="riga.id"
                                class="d-flex justify-content-between align-items-center riga" 
                                @click="sidebarCommento('Modifica Commento',riga.id,riga.content)">
                                <div v-html="riga.content"></div>
                                <div class="text-muted dx">{{ riga.author }}<br /><span class="blockquote-footer">{{ formattazioneDataOra(riga.update_at, 'it') }}</span></div>
                              </li>
                            </ul>
                            
                            </vue-perfect-scrollbar>
                          </div>
                        </b-col>                      
                        <b-col md="12" lg="6">
                          <editnotecustomer :editnote_id="campiform.customer.id" :editnote_note="campiform.customer.note" editnote_dimensione_btn="piccolo" editnote_atc_aggiorna="si" @campoNoteCustomerAggiornato="updateCampoNote"></editnotecustomer>
                          <br />

                          <dl>
                            <dt class="text-muted">Note</dt>
                            <dd v-html="campiform.customer.note"></dd>
                          </dl>
                        </b-col>
                      </b-row>

                    </b-card>
                  </b-tab>

                  <!--
                  <b-tab>
                    <template #title>
                        <feather-icon
                        icon="LockIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">Assistenti</span>
                    </template>
                    <b-card bg-variant="Default">
                      da fare ...<br /><br />
                      <a href="https://docs.google.com/document/d/1Chqf9k3_lN9P2gGNl0WVxMLNwqnJr0quFN9abiyfp0M/edit#heading=h.x98ceiwyxzh2" target="_blank">documentazione su Google Doc</a>

                    </b-card>
                  </b-tab>
                  -->


              </b-tabs>
          </b-card-body>
        </b-card>
    </div>

    <b-sidebar
      ref="sidebar_azioni"
      id="sidebar-right"
      bg-variant="white"
      :title="infoSideBar.titolo"
      right
      backdrop
      shadow 
      v-model="isSidebarOpen"
    >
      <div>
        <div class="py-0 px-2">
          
          <div class="divider">
              <div class="divider-text text-primary fw-bolder">DETTAGLI</div>
          </div>
          <b-row cols="1">
            <b-col class="riga_sidebar" v-if="infoSideBar.nome">
              <span class="font-weight-bold spacer-dettagli">Referente:</span> {{ infoSideBar.nome }}
            </b-col>
            <b-col class="riga_sidebar" v-if="infoSideBar.email">
              <span class="font-weight-bold spacer-dettagli">Email:</span> {{ infoSideBar.email }}
            </b-col>
            <b-col class="riga_sidebar" v-if="infoSideBar.cellulare">
              <span class="font-weight-bold spacer-dettagli">Cellulare:</span> {{ infoSideBar.cellulare }}
            </b-col>
            <b-col class="riga_sidebar" v-if="infoSideBar.fisso">
              <span class="font-weight-bold spacer-dettagli">Fisso:</span>  {{ infoSideBar.fisso }}
            </b-col>
            <b-col class="riga_sidebar" v-if="infoSideBar.sorgente">
              <span class="font-weight-bold spacer-dettagli">Sorgente:</span>  {{ infoSideBar.sorgente }}
            </b-col>
            <b-col class="riga_sidebar" v-if="infoSideBar.rating">
              <span class="font-weight-bold spacer-dettagli">Rating:</span>  {{ infoSideBar.rating }}
            </b-col>
          </b-row>

          <div class="divider">
            <div class="divider-text text-primary fw-bolder mt-2">AZIONI</div>
          </div>
          
          <b-row>
            <b-col md="12" lg="6">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary" block
                class="mb-1"
                 @click="isSidebarOpen = false"
              >
                <feather-icon icon="EyeIcon" /> Visualizza
              </b-button>
            </b-col>
            <b-col md="12" lg="6">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-warning" block
                class="mb-1"
                @click="$router.push({ name: 'crm-customers-edit', params: { id_riga: infoSideBar.id }})"
              >
                <feather-icon icon="Edit2Icon" /> Modifica
              </b-button>
            </b-col>
            <b-col md="12" lg="6">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-warning" block
                class="mb-1"
                @click="recuperaDatiSocietari(infoSideBar.id,infoSideBar.company_name,infoSideBar.piva)"
              >
                <feather-icon icon="RefreshCwIcon" /> Aggiorna
              </b-button>
            </b-col>
            <b-col md="12" lg="6" v-if="infoSideBar.stato == 3">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary" block
                class="mb-1"
                @click="cambioStatoCustomer(infoSideBar.id, 2)"
              >
                <feather-icon icon="RefreshCcwIcon" /> Ripristina
              </b-button>
            </b-col>
            <b-col md="12" lg="6" v-if="infoSideBar.stato == 3">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger" block
                class="mb-1"
                @click="cancellaRiga(infoSideBar.id)"
              >
                <feather-icon icon="Trash2Icon" /> Cancella definitivamente
              </b-button>
            </b-col>
            <b-col md="12" lg="6" v-else>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger" block
                class="mb-1"
                @click="cambioStatoCustomer(infoSideBar.id, 3)"
              >
                <feather-icon icon="Trash2Icon" /> Cancella
              </b-button>
            </b-col>
            <b-col md="12" lg="6">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary" block
                class="mb-1"
                :href="`mailto:${infoSideBar.email}`"
              >
                <feather-icon icon="MailIcon" /> Email
              </b-button>
            </b-col>
            <b-col md="12" lg="6">
              <newactivity field_tipo="Customer" :field_id="infoSideBar.id" :field_nome_relazione="infoSideBar.company_name"></newactivity>
            </b-col>
            <b-col md="12" lg="6">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary" block
                class="mb-1"
                @click="sidebarNuovaLicenza('Abbonamento')"
              >
                <feather-icon icon="PlusIcon" /> Abbonamento
              </b-button>
            </b-col>
            <b-col md="12" lg="6">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary" block
                class="mb-1"
                @click="sidebarNuovaLicenza('OneShot')"
              >
                <feather-icon icon="PlusIcon" /> One Shot
              </b-button>
            </b-col>
          </b-row>

        </div>

      </div>
    </b-sidebar>

    <b-sidebar
      ref="sidebar_commenti"
      id="sidebar-commenti"
      bg-variant="white"
      :title="SideBarCommenti.tipo_operazione"
      :visible="sidebar_visibile"
      right
      backdrop
      shadow
    >
      <div>
        <div class="py-0 px-2">
          <validation-observer ref="simpleRules">
            <b-form>
              <b-form-group
                label="Commento"
                label-for="task-description"
              >
                <validation-provider
                #default="{ errors }"
                name="Commento"
                rules="required"
                >
                  <quill-editor
                    id="quil-content"
                    v-model="campiform_commenti.content"
                    :options="editorOption"
                    class="border-bottom-0"
                    style="height: 250px;"
                  />
                  <div
                    id="quill-toolbar"
                    class="d-flex justify-content-end border-top-0"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold" />
                    <button class="ql-italic" />
                    <button class="ql-underline" />
                  </div>
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div class="mt-2">
                <b-button
                  variant="outline-primary"
                  type="submit"
                  @click.prevent="validationFormCommento()"
                  >
                  Salva
                </b-button>
                <b-button
                  v-if="SideBarCommenti.tipo_operazione == 'Modifica Commento'"
                  variant="outline-danger" 
                  class="ml-2"
                  type="submit"
                  @click.prevent="CancellaCommento(SideBarCommenti.id_commento)"
                  >
                  Cancella
                </b-button>
              </div>
            </b-form>
          </validation-observer>

        </div>
      </div>
    </b-sidebar>

    <b-sidebar
      ref="sidebar_nuova_licenza"
      id="sidebar-nuova-licenza"
      bg-variant="white"
      width="450px"
      title="Nuova Licenza"
      right
      backdrop
      shadow
    >
      <div>
        <div class="py-0 px-2">
          <div class="text-center my-3" v-if="Caricato_sidebar_nuova_licenza == false">
            <b-spinner
              variant="primary"
              style="width: 3rem; height: 3rem;"
              class="mr-1"
              label="loading"
            />
          </div>
          <div v-else>
            <b-form-radio-group
              v-model="nuova_licenza_value"
              :options="nuova_licenza_options"
              :state="state_select_nuova_licenza"
              class="selezione_licenza"
              name="radio-validation"
              stacked
              html
            >
              <b-form-valid-feedback class="text-right my-2" :state="state_select_nuova_licenza">
                <b-button
                  variant="outline-primary"
                  type="submit"
                  @click.prevent="attivaNuovaLicenza(infoSideBarActionNuovaLicenza.tipo_licenza)"
                  >
                  Inserisci nuova licenza
                </b-button>
              </b-form-valid-feedback>
            </b-form-radio-group>
          </div>
        </div>
      </div>
    </b-sidebar>

    <b-sidebar
      ref="sidebar_azioni_licenze"
      id="sidebar-right-azioni-licenze"
      bg-variant="white"
      :title="infoSideBarActionLicenze.titolo"
      right
      backdrop
      shadow
    >
      <div>
        <div class="py-0 px-2">
          
          <div class="divider">
              <div class="divider-text text-primary fw-bolder">DETTAGLI</div>
          </div>
          <b-row cols="1">
            <b-col class="riga_sidebar">
              <span class="font-weight-bold">Ref:</span> {{ infoSideBarActionLicenze.ref_code }}
            </b-col>
            <b-col class="riga_sidebar">
              <span class="font-weight-bold">status:</span> {{ infoSideBarActionLicenze.status_name }}
            </b-col>
          </b-row>

          <div class="divider">
            <div class="divider-text text-primary fw-bolder mt-2">AZIONI</div>
          </div>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-warning" block
            class="mb-1"
            @click="$router.push({ name: 'crm-licenze-show', params: { id_riga: infoSideBarActionLicenze.id }})"
          >
            <feather-icon icon="EyeIcon" /> Visualizza Licenza
          </b-button>

        </div>

      </div>
    </b-sidebar>

    <b-modal
      ref="openapi-modal"
      size="lg"
      centered
      hide-footer
      title="Aggiorna Dati Societari"
      @hide="resetOpenapimodal"
    >
      <b-tabs content-class="pt-1" fill>
        <b-tab active>
          <template #title>
            <span>per Partita Iva</span> 
            <b-badge variant="success" style="margin-left: 10px; background-color: #009688;">Consigliata</b-badge>
          </template>
          
          <div v-if="this.controllo_piva_presente === false">
            <b-form>
              <b-form-group label-for="ricerca_piva">
                <b-form-input id="basicInput" placeholder="Partita Iva" v-model="campiformOpenApiRicercaPerPiva.piva" />
              </b-form-group>

              <b-button
                variant="primary"
                type="submit"
                :disabled="action_press_openApiRicercaPerPiva"
                @click.prevent="openApiRicercaPerPiva()"
              >
                <div v-if="action_press_openApiRicercaPerPiva">Ricerca in corso <b-spinner small class="ml-1" /></div>
                <div v-else>Recupera Dati</div> 
              </b-button>
            </b-form>
          </div>
          <div v-else>
            <div v-if="bottone_rimandare_scheda_cliente">
              <p>La partita iva cercata è già associata al cliente <strong>{{ this.dati_controllo_piva.company_name }}</strong></p>
              <p>
                <b-button
                  variant="primary"
                  @click="$router.push({ name: 'crm-customers-show', params: { id_riga: dati_controllo_piva.userable_id }})"
                >
                  <feather-icon icon="EyeIcon" /> Visualizza Cliente
                </b-button>
              </p>
            </div>
            <div v-if="bottone_ripristino_scheda_cliente">
              <p>La partita iva cercata è già associata al cliente <strong>{{ this.dati_controllo_piva.company_name }}</strong></p>
              <p>
                <b-button
                  variant="primary"
                  @click="ripristinaClienteDaSoftDelete(dati_controllo_piva.userable_id)"
                >
                  <feather-icon icon="RefreshCcwIcon" /> Ripristina Cliente
                </b-button>
              </p>
            </div>
          </div>

        </b-tab>
        <b-tab>
          <template #title>
            <span>per Ragione Sociale</span>
          </template>

          <div v-if="this.controllo_piva_presente_due === false">
            <b-form>
              <b-form-group label-for="ricerca_rag_sociale">
                <b-form-input id="basicInput" placeholder="Ragione Sociale" v-model="campiformOpenApiRicercaRagioneSociale.rag_sociale" />
              </b-form-group>

              <div v-if="open_api_ricerca_items" class="my-2">
                <b-overlay :show="isLoadingRecuperoDati" rounded="sm" spinner-small spinner-variant="primary">
                  <b-table striped :items="open_api_ricerca_items" :fields="open_api_ricerca_fields">
                    <template #cell(denominazione)="data">
                      {{ data.item.denominazione }}
                    </template>
                    <template #cell(id)="data">
                      <b-button @click="pippo(data.item.id)" variant="outline-primary">Recupera dati</b-button>
                    </template>
                  </b-table>
                </b-overlay>
              </div>

              <b-button
                variant="primary"
                type="submit"
                :disabled="action_press_openApiRicercaPerRagioneSociale"
                @click.prevent="openApiRicercaPerRagioneSociale()"
              >
                <div v-if="action_press_openApiRicercaPerRagioneSociale">Ricerca in corso <b-spinner small class="ml-1" /></div>
                <div v-else>Recerca Azienda</div> 
              </b-button>
            </b-form>
          </div>
          <div v-else>
            <div v-if="bottone_rimandare_scheda_cliente_due">
              <p>La partita iva cercata è già associata al cliente <strong>{{ this.dati_controllo_piva_due.company_name }}</strong></p>
              <p>
                <b-button
                  variant="primary"
                  @click="$router.push({ name: 'crm-customers-show', params: { id_riga: dati_controllo_piva_due.userable_id }})"
                >
                  <feather-icon icon="EyeIcon" /> Visualizza Cliente
                </b-button>
              </p>
            </div>
            <div v-if="bottone_ripristino_scheda_cliente_due">
              <p>La partita iva cercata è già associata al cliente <strong>{{ this.dati_controllo_piva_due.company_name }}</strong></p>
              <p>
                <b-button
                  variant="primary"
                  @click="ripristinaClienteDaSoftDelete(dati_controllo_piva_due.userable_id)"
                >
                  <feather-icon icon="RefreshCcwIcon" /> Ripristina Cliente
                </b-button>
              </p>
            </div>
          </div>
          
        </b-tab>
      </b-tabs>
      
    </b-modal>
      
  </div>
</template>

<script>
import router from '@/router'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import it from 'vee-validate/dist/locale/it.json'
import { BTabs, BTab, BRow, BCol, BCard, BCardTitle, BCardHeader, BCardBody, BCardText, BSpinner, BFormGroup, BForm, BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BDropdownDivider, BFormSelect, BDropdown, BDropdownItem, BButton, BSidebar, VBToggle, BBadge, BFormTextarea, BPagination, BFormRadioGroup, BFormValidFeedback, BOverlay, VBTooltip, BTable, BModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import showdata from '@core/components/showdata/showdata.vue'
import { prezzoFormattato } from '@core/utils/funzioniGlobali' 
import moment from 'moment'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required
} from '@validations'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

import store from '@/store/index'
import { logoutGeneral } from "@core/components/logout/logout.vue"

import newactivity from '@core/components/newactivity/newactivity.vue'
import editnotecustomer from '@core/components/editnotecustomer/editnotecustomer.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard, 
    BCardTitle, 
    BCardHeader, 
    BCardBody, 
    BCardText, 
    BSpinner,
    BFormGroup,
    BForm, 
    BInputGroup, 
    BFormInput, 
    BInputGroupPrepend, 
    BInputGroupAppend, 
    BDropdownDivider,
    BFormSelect, 
    BDropdown, 
    BDropdownItem, 
    BButton,
    BSidebar, 
    VBToggle, 
    BBadge,
    BFormTextarea,
    BPagination, 
    BFormRadioGroup,
    BFormValidFeedback,
    BOverlay,
    BTable, 
    BModal, 

    showdata,
    VuePerfectScrollbar,
    ValidationProvider,
    ValidationObserver,
    localize,
    it,
    quillEditor,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent, 
    logoutGeneral,

    newactivity,
    editnotecustomer,

    Swiper,
    SwiperSlide, 
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    return {
      prezzoFormattato,
    }
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: '1',
        //centeredSlides: true,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        }
      },

      userData: {},
      perfectScrollbarSettings: '',
      editorOption: {
        modules: {
          toolbar: '#quill-toolbar',
        },
        placeholder: 'scrivi commento ...',
      },
      id_italina: '1',
      campiform: {
        customer: {
          id: '',
          user_id: '',
          reseller_id: '',
          reseller_name: '',
          assistant_id: '',
          assistant_name: '',
          hr_id: '',
          evaluation_id: '',
          evaluation_name: '',
          rating: '',
          created_at: '',
          source: [],
          note: '',
          lead_registered_at: '',
          section: '',
          payment: [ ],
        },
        user: {
          email: '', 
          name: '', 
          surname: '',
          mobile_phone: '',
          phone: '',
          website: '',
          newsletter: '',
        },
        billing: {
          country_id: '',
          country_name: '',
          province_id: '',
          province_name: '',
          province_foreign: '', 
          address: '',
          zip: '',
          city: '',
          company_name: '',
          vat_number: '',
          fiscal_code: '',
          pec: '',
          dest_code: '',
          vat_scheme_id: null,
          vat_scheme_name: '',
          note_xml: '', 
          split_payment: '',
          id_fic: '',
          url_analisi_cliente_fic: '',
        },
        openapi: {
          scaricato: false,
        }
      },
      list_comments: { },
      n_commenti: 0,
      campiform_commenti: {
        content: '',
        id_commento: '',
        tipo_operazione: 'new'
      },
      campiform_commenti_start: {
        content: '',
        tipo_operazione: 'new'
      },
      sidebar_visibile: false,
      options_usercrm: [ ],
      options_evalution: [ ],
      options: [ ],
      options_province: [ ],
      options_regine_iva: [ ],
      area_select_provincia: true,
      Caricato: false,
      errore_caricamento: false,
      infoSideBar: {
        id: '',
        company_name: '', 
        id_fic: '', 
        url_analisi_cliente_fic: '', 
        ruoloUtente: '', 
        stato: '',
        piva: '', 
      },
      SideBarCommenti: { 
        tipo_operazione: '', 
        id_card: '', 
        id_commento: '' 
      },
      infoSideBarActionLicenze: {
        tipo_licenza: '',
        titolo: '',
        id: '',
        ref_code: '',
        status_name: '',
      },
      required,

      isSidebarOpen: false,

      action_press: false,

      dir: false,

      searchTerm: '',
      infoSideBar: {
        id: '',
        titolo: '',
        nome: '',
        email: '',
        cellulare: '',
        fisso: '',
        sorgente: '', 
        piva: '', 
        note: '',
        rating: '', 
        stato: ''
      },

      Caricato_sidebar_nuova_licenza: false,
      nuova_licenza_value: null,
      nuova_licenza_options: [],
      infoSideBarActionNuovaLicenza: {
        tipo_licenza: '',
      },

      openapiModal: {
        id_riga: '',
        rag_sociale: '',
        piva: '',

      },
      campiformOpenApiRicercaPerPiva: { 
        piva: '',
        id_riga: '',
        userable_type: 'Customer', //fisso per la sezione CUSTOMER del fronend vue (serve solo all'api per lavoro e non fare casino nelle operazioni richieste)
      },
      action_press_openApiRicercaPerPiva: false,
      campiformOpenApiRicercaRagioneSociale: { 
        rag_sociale: '',
        id_riga: '',
        userable_type: 'Customer', //fisso per la sezione CUSTOMER del fronend vue (serve solo all'api per lavoro e non fare casino nelle operazioni richieste)
      },
      action_press_openApiRicercaPerRagioneSociale: false,

      isLoadingRecuperoDati: false,
      open_api_ricerca_fields: ['denominazione', 'id'],
      open_api_ricerca_items: '',      

      controllo_piva_presente: false,
      bottone_rimandare_scheda_cliente: false,
      bottone_ripristino_scheda_cliente: false,
      dati_controllo_piva: {},
      controllo_piva_presente_due: false,
      bottone_rimandare_scheda_cliente_due: false,
      bottone_ripristino_scheda_cliente_due: false,
      dati_controllo_piva_due: {}
      
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    state_select_nuova_licenza() {
      return Boolean(this.nuova_licenza_value)
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        2 : 'light-dark',
        3 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    sourceVariant(){
      const statusColor = {
        'App AgentScout' : 'light-success',
        'Bing' : 'light-warning',
        'Blog Sito AgentScout' : 'light-success',
        'Chiamata diretta' : 'light-success',
        'Email' : 'light-success',
        'Facebook Ads' : 'light-warning',
        'Facebook Messenger' : 'light-info',
        'Facebook Modulo' : 'light-info',
        'Google Ads' : 'light-warning',
        'Instagram' : 'light-info',
        'Linkedin' : 'light-info',
        'Reseller' : 'light-success',
        'Seo Sito AgentScout' : 'light-success',
        'Spotify' : 'light-warning',
        'Whatsapp' : 'light-warning',
        'Altro' : 'light-success',
      }

      return status => statusColor[status]
    },
    statusVariantEvaluation() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        2 : 'light-warning',
        3 : 'light-dark',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  created() {
    //controllo tipo di employee (es. Admin o SuperAdmin o ...)
    this.userData = JSON.parse(localStorage.getItem('userData'))

    const countryPromise = this.$http.get('api/fs/country/list')
    const provincePromise = this.$http.get('api/fs/province/list')
    const userCrmPromise = this.$http.get('api/crm/user/list?columnFilters={"role.type":"employee"}&page=1&perPage=100&status=active')
    const vatSchemePromise = this.$http.get('api/fs/employee/vat-scheme/list')
    const infoCustomerPromise = this.$http.get('api/crm/customer/card/'+router.currentRoute.params.id_riga)
    const infoBillingPromise = this.$http.get('api/crm/billing/customer/card/'+router.currentRoute.params.id_riga)
    const CommentsPromise = this.$http.get('api/crm/comments/customer/list/'+router.currentRoute.params.id_riga+'?sort[]={"field":"created_at","type":"desc"}&page=1&perPage=100')

    Promise.all([countryPromise, provincePromise, userCrmPromise, vatSchemePromise, infoCustomerPromise, infoBillingPromise, CommentsPromise]).then(response => {
      if(response[0].data.statusCode===200){
        //caricamento lista nazioni
        this.options = response[0].data.reply

        if(response[1].data.statusCode===200){
          //caricamento lista province italiane
          this.options_province = response[1].data.reply

          if(response[2].data.statusCode===200){
            //caricamento lista "user Crm" (sia come "reseller" sia come "assistent")
            this.options_usercrm = response[2].data.reply.data
                
            if(response[3].data.statusCode===200){
              //caricamento lista regime iva
              this.options_regine_iva = response[3].data.reply  
              
              if(response[4].data.statusCode===200){
                //dati customer
                this.campiform.customer = response[4].data.reply.customerData                  

                if(response[5].data.statusCode===200){
                  //nome reseller
                  var id_reseller = this.campiform.customer.reseller_id
                  var reseller = this.options_usercrm.filter(function(elem){
                      if(elem.id == id_reseller){
                      return elem.name
                      }
                  })
                  this.campiform.customer.reseller_name = reseller[0].name+' '+reseller[0].surname

                  //nome assistent
                  var id_assistant = this.campiform.customer.assistant_id
                  var assistent = this.options_usercrm.filter(function(elem){
                      if(elem.id == id_assistant){
                      return elem.name
                      }
                  })
                  this.campiform.customer.assistant_name = assistent[0].name+' '+assistent[0].surname
                    
                  //caricamento dati billing
                  this.campiform.billing = response[5].data.reply.billingData

                  //url per link alla Analisi cliente su FIC
                  this.campiform.billing.url_analisi_cliente_fic = 'https://secure.fattureincloud.it/clients-analysis-'+this.campiform.billing.id_fic

                  //nazione
                  var id_nazione = this.campiform.billing.country_id
                  var nazione = this.options.filter(function(elem){
                      if(elem.value == id_nazione){
                      return elem.text
                      }
                  })
                  this.campiform.billing.country_name = nazione[0].text

                  //provincia
                  var id_provincia = this.campiform.billing.province_id
                  var provincia = this.options_province.filter(function(elem){
                      if(elem.value == id_provincia){
                        return elem.text
                      }
                  })
                  this.campiform.billing.province_name = provincia[0].text

                  //regime iva
                  var id_regime_iva = this.campiform.billing.vat_scheme_id
                  var regime_iva = this.options_regine_iva.filter(function(elem){
                      if(elem.value == id_regime_iva){
                        return elem.text
                      }
                  })
                  this.campiform.billing.vat_scheme_name = regime_iva[0].text

                  this.$http.get('api/crm/billing/financialstatements/'+this.campiform.billing.id).then(response_bilancio => {
                    if((response_bilancio.data.statusCode>=200) && (response_bilancio.data.statusCode<=299)){
                      //caricamento dati billing
                      this.campiform.openapi = response_bilancio.data.reply

                      console.log(this.campiform.openapi)
                    } else {
                      //risposta negativa (errore sul server)
                    }
                  }).catch(e => {
                    console.log(e);
                  });

                  if(response[6].data.statusCode===200){
                    //caricamento comment
                    this.list_comments = response[6].data.reply.data
                    this.n_commenti = response[6].data.reply.meta.total

                    //estrazione dati User (sicrono)
                    this.$http.get('api/crm/user/customer/card/'+this.campiform.customer.id).then(response_user => {
                      if((response_user.data.statusCode>=200) && (response_user.data.statusCode<=299)){
                        //risposta positiva
                        this.campiform.user = response_user.data.reply.userData
                        
                        this.Caricato = true;
                        this.errore_caricamento = false;
                      } else {
                        //risposta negativa (errore sul server)
                        
                        this.Caricato = false;
                        this.errore_caricamento = true;
                      }
                    }).catch(e => {
                      console.log(e);
                    });
                  } else {
                  this.Caricato = false;
                  this.errore_caricamento = true;
                  }
                } else {
                this.Caricato = false;
                this.errore_caricamento = true;
                }                   
              } else {
              this.Caricato = false;
              this.errore_caricamento = true;
              }
            } else {
            this.Caricato = false;
            this.errore_caricamento = true;
            }

            
          } else {
            this.Caricato = false;
            this.errore_caricamento = true;
          }
        } else {
          this.Caricato = false;
          this.errore_caricamento = true;
        }
      } else {
        this.Caricato = false;
        this.errore_caricamento = true;
      }
    })
    
  },
  methods: {
    formattazioneArray(ArrayPassato) {
      return ArrayPassato.map(obj => obj.name).join(', ');
    },

    forzaSyncFIC(id_customer){
      //console.log("richiesta sincro per ID_CUSTOMER -> "+id_customer)

      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma la sincronizzazione dei dati verso Fatture in Cloud",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.action_press = true

          this.$http.post('api/crm/customer/sync_fic/'+id_customer)
          .then(response_sync => {
            if((response_sync.data.statusCode>=200) && (response_sync.data.statusCode<=299)){
              if(response_sync.data.reply.fic_update_customer_result === true){
                //risposta positiva
                this.$swal({
                    icon: 'success',
                    title: 'Anagrafica cliente aggiornata correttamente su Fatture in Cloud',
                    confirmButtonText: 'chiudi',
                    customClass: {
                    confirmButton: 'btn btn-success',
                    },
                })
              } else {
                //errore di FIC
                this.$swal({
                    icon: 'error',
                    title: 'Errore',
                    text: response_sync.data.reply.messaggio,
                    customClass: {
                    confirmButton: 'btn btn-outline-primary',
                    },
                })
              }
              
              this.action_press = false
            
            } else {
              //risposta negativa (errore sul server)
              this.$swal({
                  icon: 'error',
                  title: 'Si è verificato un errore nella sincronizzazione dai dati su Fatture in Cloud',
                  text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                  customClass: {
                  confirmButton: 'btn btn-outline-primary',
                  },
              })
                
              this.action_press = false
            }
          }).catch(e => {
            console.log(e);
          });

        }
      })

    },

    forzaCreateSyncFIC(id_customer){
      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma la creazione del cliente su Fatture in Cloud",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.action_press = true

          this.$http.post('api/crm/customer/new_in_fic/'+id_customer)
          .then(response_sync => {
            if((response_sync.data.statusCode>=200) && (response_sync.data.statusCode<=299)){
              if(response_sync.data.reply.fic_new_customer_create === true){
                //risposta positiva
                this.$swal({
                    icon: 'success',
                    title: 'Anagrafica cliente creata correttamente su Fatture in Cloud',
                    confirmButtonText: 'chiudi',
                    customClass: {
                    confirmButton: 'btn btn-success',
                    },
                })

                this.campiform.billing.id_fic = response_sync.data.reply.fic_new_customer.data.id

              } else {
                //errore di FIC
                this.$swal({
                    icon: 'error',
                    title: 'Errore',
                    text: response_sync.data.reply.messaggio,
                    customClass: {
                    confirmButton: 'btn btn-outline-primary',
                    },
                })
              }
              
              this.action_press = false
            
            } else {
              //risposta negativa (errore sul server)
              this.$swal({
                  icon: 'error',
                  title: 'Si è verificato un errore la creazione cliente su Fatture in Cloud',
                  text: response_sync.data.reply.message,
                  customClass: {
                  confirmButton: 'btn btn-outline-primary',
                  },
              })
                
              this.action_press = false
            }
          }).catch(e => {
            console.log(e);
          });

        }
      })

    },

    apriSchedaClienteSuStripe(codice) {
      const url = "https://dashboard.stripe.com/test/customers/"+codice;
      window.open(url, "_blank");
    },

    sidebarActionShow(id, azienda, nome_cognome, email, mobile_phone, phone, sorgente, vat_number, note, rating, stato){

    console.log("azienda -> " + azienda);

      this.infoSideBar = { id: id,
        titolo: azienda,
        nome: nome_cognome,
        email: email,
        cellulare: mobile_phone,
        fisso: phone,
        sorgente: sorgente, 
        piva: vat_number,  
        note: note, 
        rating: rating, 
        stato: stato, };

      this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
    },
    cambioStatoCustomer(id_riga,id_stato){
      let messaggio='';
      let btn_color='';
      if(id_stato == 3){
        messaggio+="confermi la cancellazione del Cliente?";
        btn_color+='btn btn-outline-danger';
      } else {
        messaggio+="confermi il ripristino del Cliente?";
        btn_color+='btn btn-outline-primary';
      }

      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: messaggio,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: btn_color,
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.post('api/crm/customer/cambiostato/'+id_riga+'/'+id_stato)
            .then(response => { 
                if(response.data.statusCode===200){
                  //risposta positiva
                  this.$swal({
                    icon: 'success',
                    title: response.data.reply.reply,
                    //text: 'operazione effettuata correttamente',
                    confirmButtonText: 'chiudi',
                    customClass: {
                    confirmButton: 'btn btn-success',
                    },
                  })

                  //chiudi sidebar
                  this.$refs.sidebar_azioni.hide();

                  //aggiorna dati visualizzati
                  if(id_stato == 3){
                    this.campiform.customer.status_id = id_stato;
                    this.campiform.customer.status.value = id_stato;
                    this.campiform.customer.status.text = 'Soft Delate';
                  } else if(id_stato == 2){
                    this.campiform.customer.status_id = id_stato;
                    this.campiform.customer.status.value = id_stato;
                    this.campiform.customer.status.text = 'Inattivo';
                  } else {
                    this.campiform.customer.status_id = 1;
                    this.campiform.customer.status.value = id_stato;
                    this.campiform.customer.status.text = 'Attivo';
                  }                  
                    
                } else {
                //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    },
    cancellaRiga(id_riga){
      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma la cancellazione definitiva del Cliente?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.post('api/crm/customer/delete/'+id_riga)
            .then(response => { 
                console.log("risposta server --------")
                console.log(response.data)

                if(response.data.statusCode===200){
                    //risposta positiva
                    this.$router.replace('/crm/customers')
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: response.data.reply.reply,
                                //text: 'operazione effettuata correttamente',
                                confirmButtonText: 'chiudi',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })
                        })
                    
                } else {
                //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    },
    formattazioneDataOra(created_at,lang) {
      if (created_at) {
        return moment(String(created_at)).locale(lang).format('DD/MM/YYYY - HH:mm:ss')
      } else {
        return '---'
      }
    },
    formattazioneDataGiorniConversione(registrated_at, converted_at, lang) {
      if (registrated_at && converted_at) {
        const diffDays = moment(converted_at).diff(moment(registrated_at), 'days');
        return `${diffDays} giorni`;
      } else {
        return '-';
      }
    },

    sidebarCommento(tipo_operazione, id_commento, testo_commento){
      console.log("id_card -> "+router.currentRoute.params.id_riga+"\nid_commento -> "+id_commento)
      this.SideBarCommenti = { tipo_operazione: tipo_operazione, id_card: router.currentRoute.params.id_riga, id_commento: id_commento };

      if( tipo_operazione == 'Nuovo Commento' ){
        this.campiform_commenti.tipo_operazione = 'new'
        this.campiform_commenti.content = ''
        this.campiform_commenti.id_commento = ''
      } else {
        this.campiform_commenti.tipo_operazione = 'edit'
        this.campiform_commenti.content = testo_commento
        this.campiform_commenti.id_commento = id_commento
      }

      this.$root.$emit('bv::toggle::collapse', 'sidebar-commenti')
    },
    validationFormCommento(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line

          //c'è da aggregare altre informazioni da inviare all'api per creare l'utente ???
          console.log(this.campiform_commenti)

          let url_api = '';
          if( this.campiform_commenti.tipo_operazione == 'new' ){
            url_api+='api/crm/comments/customer/card/'+router.currentRoute.params.id_riga
          } else {
            url_api+='api/crm/comments/customer/card/'+router.currentRoute.params.id_riga+'/'+this.campiform_commenti.id_commento;
          }

          this.$http.post(url_api, 
            this.campiform_commenti 
            ).then(response => { 
              console.log("risposta server --------")
              console.log(response.data)

              if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
                //risposta positiva
                if(response.data.reply.commentData.id > 0){
                  //salvataggio effettuato + aggiorna lista commenti (ultimo salvato primo della lista)
                  if( this.campiform_commenti.tipo_operazione == 'new' ){
                    //aggiungi alla lista
                    this.list_comments.unshift(response.data.reply.commentData)
                    
                    //aggiorna conteggio commenti
                    //console.log("conta -> "+this.list_comments.length)
                    this.n_commenti = this.list_comments.length

                  } else {
                    //modifica -> aggiorna relativa voce della lista
                    this.list_comments = this.list_comments.map(object => {
                      if (object.id == response.data.reply.commentData.id) {
                        return {...object,
                          content: response.data.reply.commentData.content,
                          update_at: response.data.reply.commentData.update_at,
                          author: response.data.reply.commentData.author};
                      }
                      return object;
                    });
                  }

                  //chiudo finestra ...
                  this.$refs.sidebar_commenti.hide();
                  //azzero form commento
                  this.campiform_commenti = this.campiform_commenti_start

                  //reset validation form
                  this.$refs.simpleRules.reset();

                  this.$swal({
                      icon: 'success',
                      title: 'Nuovo commento salvato correttamente',
                      confirmButtonText: 'chiudi',
                      customClass: {
                      confirmButton: 'btn btn-success',
                      },
                  })
                } else {
                  //questo non dovrebbe mai accadere ... ma non si sa mai -> visualizzo risposta ma non faccio redirect
                  this.$swal({
                      icon: 'warning',
                      title: 'Si è verificato un errore',
                      text: 'Salvataggio riuscito ma con errore '+response.data.statusCode+', ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }

              } else {
                //risposta negativa (errore sul server)
                this.$swal({
                    icon: 'error',
                    title: 'Si è verificato un errore',
                    text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                    customClass: {
                    confirmButton: 'btn btn-outline-primary',
                    },
                })
              }
            }).catch(e => {
              console.log(e);
            });

        }
      })
    },
    CancellaCommento(id_riga_commento){
      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma la cancellazione definitiva del commento?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.post('api/crm/comments/customer/delete/'+router.currentRoute.params.id_riga+'/'+id_riga_commento)
            .then(response => { 
                if(response.data.statusCode===200){
                  //risposta positiva
                  this.$swal({
                      icon: 'success',
                      title: response.data.reply.reply,
                      //text: 'operazione effettuata correttamente',
                      confirmButtonText: 'chiudi',
                      customClass: {
                      confirmButton: 'btn btn-success',
                      },
                  })

                  //chiudi sidebar
                  this.$refs.sidebar_commenti.hide();

                  //rimuovi il commento eliminato dalla lista
                  this.list_comments = this.list_comments.filter(riga => riga.id != id_riga_commento);

                  //aggiorna conteggio commenti
                  this.n_commenti = this.list_comments.length
                    
                } else {
                 //chiudi sidebar
                 this.$refs.sidebar_commenti.hide();

                 //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    },
    sidebarActionLicenze(tipo, id, ref_code, status_name) {
      this.infoSideBarActionLicenze = { 
        tipo_licenza: tipo,
        id: id,
        titolo: 'ID Licenza '+ref_code,
        ref_code: ref_code,
        status_name: status_name,
      };

      this.$root.$emit('bv::toggle::collapse', 'sidebar-right-azioni-licenze')
    },
    
    onRowClick(params) {
      // params.row - row object 
      // params.column - column object
      // params.rowIndex - index of this row on the current page.
      // params.event - click event
      console.log(params.row)
      console.log(params.event)
    },
    sidebarNuovaLicenza(tipo) {
      let route = '';
      if(tipo == 'Abbonamento'){
        route = 'subscription/';
      } else {
        route = 'oneshot/';
      }

      this.infoSideBarActionNuovaLicenza = { 
        tipo_licenza: tipo
      };

      //reimposta loader per caricamento licenze da stripe
      this.Caricato_sidebar_nuova_licenza = false

      //cancella lista licenza di stripe eventualmente caricate in precedenza
      this.nuova_licenza_options = [];

      //evento apri sidebar
      this.$root.$emit('bv::toggle::collapse', 'sidebar-nuova-licenza')

      this.$http.get('api/crm/stripe/product/'+route+'list').then(response => { 
        if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
          
          /*
          console.log("dati prodotti su Stripe")
          console.log(response.data.reply)
          console.log("lunghezza array -> "+response.data.reply.length)
          */
          
          //caricamento lista licenze per corretta visualizzazione
          let u = 0;
          while (u < response.data.reply.length) {
            let option = response.data.reply[u].name;
            
            this.nuova_licenza_options[u] = { 
              html: option,
              value: response.data.reply[u].default_price.id+'----'+response.data.reply[u].id
            },            
            u++;
          }

          //console.log(this.nuova_licenza_options)

          this.Caricato_sidebar_nuova_licenza = true

        } else {
          //risposta negativa (errore sul server)
          if(response.data.reply.message != ''){
            this.$swal({
                icon: 'warning',
                title: 'Si è verificato un errore',
                text: response.data.reply.message,
                customClass: {
                confirmButton: 'btn btn-outline-primary',
                },
            })
          } else {
            this.$swal({
                icon: 'error',
                title: 'Impossibile recuperare i dati dei prodotti su Stripe',
                text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                customClass: {
                confirmButton: 'btn btn-outline-primary',
                },
            })
          }

          this.Caricato_sidebar_nuova_licenza = false
        }

      }).catch(e => {
        console.log(e);
      });
    },
    attivaNuovaLicenza(tipo){
      /*
      console.log("attivaNuovaLicenza ------------")
      console.log("id_licenza selezionata -> "+this.nuova_licenza_value)
      console.log("va inviata all'api come variabile 'price_id' + il valore di customer_id come 'customer_id'")
      */
     
      //split dei dati di stripe
      const dati_stripe = this.nuova_licenza_value.split('----');

      this.$http.post('api/crm/license/card', {
        price_id: dati_stripe[0], 
        product_id: dati_stripe[1], 
        customer_id: router.currentRoute.params.id_riga
      }).then(response => { 
        //chiudi sidebar
        this.$refs.sidebar_nuova_licenza.hide();

        if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
          //salvataggio effettuato
          console.log(response.data)

          this.$swal({
              icon: 'success',
              title: 'Licenza inserita correttamente',
              //text: 'operazione effettuata correttamente',
              confirmButtonText: 'chiudi',
              customClass: {
              confirmButton: 'btn btn-success',
              },
          })
          
        } else {
          //risposta negativa (errore sul server)
          if(response.data.reply.message != ''){
            this.$swal({
              icon: 'warning',
              title: 'Si è verificato un errore',
              text: response.data.reply.message,
              customClass: {
              confirmButton: 'btn btn-outline-primary',
              },
            })
          } else {
            this.$swal({
              icon: 'error',
              title: 'Impossibile attivare la nuova licenza per questo cliente',
              text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
              customClass: {
              confirmButton: 'btn btn-outline-primary',
              },
            })
          }
        }
      }).catch(e => {
        this.$swal({
          icon: 'error',
          title: 'Impossibile salvare la nuova licenza per questo cliente',
          text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
          customClass: {
          confirmButton: 'btn btn-outline-primary',
          },
        })
      });

    },
    updateCampoNote(nuovoValore){
      console.log('updateCampoNote -----')
      this.campiform.customer.note = nuovoValore;
    },
    recuperaDatiSocietari(id_riga,rag_sociale,piva){
      this.openapiModal = {
        id_riga: id_riga,
        rag_sociale: rag_sociale,
        piva: piva,
      }

      this.campiformOpenApiRicercaPerPiva.id_riga = id_riga;

      if((piva !== null) && (piva !== undefined) && (piva != '')){
        //auto completo il form per "partita iva" di ricerca sulla modale
        this.campiformOpenApiRicercaPerPiva.piva = piva;
      }

      this.campiformOpenApiRicercaRagioneSociale.id_riga = id_riga;

      if((rag_sociale !== null) && (rag_sociale !== undefined) && (rag_sociale != '')){
        //auto completo il form per "ragione sociale" di ricerca sulla modale
        this.campiformOpenApiRicercaRagioneSociale.rag_sociale = rag_sociale;
      }

      this.$refs['openapi-modal'].show()
    },
    resetOpenapimodal(){
      this.openapiModal = {
        id_riga: '',
        rag_sociale: '',
        piva: '',
      }

      //reset tutte i form della modale
      this.resetCampiRicercaOpenApiTutti();
    },
    openApiRicercaPerPiva(){
      //console.log('Si parte - openApiRicercaPerPiva()')
      //console.log(this.campiformOpenApiRicercaPerPiva)

      this.action_press_openApiRicercaPerPiva = true

      this.$http.post('api/crm/customer/openapi/ricerca/recuperadati', 
        this.campiformOpenApiRicercaPerPiva 
        ).then(response => { 
          console.log("risposta server --------")
          console.log(response.data)

          if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
            //risposta positiva dal server
            this.action_press_openApiRicercaPerPiva = false;

            if(response.data.reply.controllo_piva_presente === true){
              //Risposta dal controllo della P.Iva già presente su un cliente
              this.controllo_piva_presente = true;
              this.dati_controllo_piva = response.data.reply;

              if(response.data.reply.deleted_at	=== null){
                //solo bottone per rimandare a scheda cliente
                this.bottone_rimandare_scheda_cliente = true;
                this.bottone_ripristino_scheda_cliente = false;
              } else {
                //bottone per ripristino cliente e poi rimandare a scheda cliente
                this.bottone_rimandare_scheda_cliente = false;
                this.bottone_ripristino_scheda_cliente = true;
              }

            } else {
              //Risposta alle operazioni di recupero dati da OpenApi

              //esito negativo da OpenApi
              if(response.data.reply.status == 'errore'){
                //notifica errore
                this.$swal({
                    icon: 'error',
                    title: 'Si è verificato un errore',
                    text: response.data.reply.messaggio,
                    customClass: {
                    confirmButton: 'btn btn-danger',
                    },
                })

              } else {
                //esito ok da OpenApi
                this.$router.replace('/crm/leads/show/'+this.campiformOpenApiRicercaPerPiva.id_riga)
                .then(() => {
                  this.$swal({
                      icon: 'success',
                      title: 'Dati sociatari e bilanci aggiornati',
                      confirmButtonText: 'chiudi',
                      customClass: {
                      confirmButton: 'btn btn-success',
                      },
                  })
                })

              }

            }
            
          } else {
            //risposta negativa (errore sul server)
            this.$swal({
                icon: 'error',
                title: 'Si è verificato un errore',
                text: response.data.reply.message,
                customClass: {
                confirmButton: 'btn btn-outline-primary',
                },
            })

            this.action_press_openApiRicercaPerPiva = false;
          }
        }).catch(e => {
          console.log(e);
        });

    },
    ripristinaClienteDaSoftDelete(userable_id){
      console.log("ripristinaClienteDaSoftDelete -> " + userable_id);

      this.$http.post('api/crm/customer/ricovery/' + userable_id).then(response => { 
          console.log("risposta server 'ricovery' --------")
          //console.log(response.data)

          if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
            //risposta positiva
            
            //redirect con messaggio specifico
            this.$router.replace('/crm/customers/show/' + userable_id)
            .then(() => {
              this.$swal({
                  icon: 'success',
                  title: 'Cliente ripristinato correttamente',
                  confirmButtonText: 'chiudi',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
              })
            })
            
          } else {
            //risposta negativa (errore sul server)
            this.$swal({
                icon: 'error',
                title: 'Si è verificato un errore',
                text: response.data.reply.message,
                customClass: {
                confirmButton: 'btn btn-outline-primary',
                },
            })

            this.action_press_openApiRicercaPerRagioneSociale = false;
          }
        }).catch(e => {
          console.log(e);
        });
    },
    openApiRicercaPerRagioneSociale(){
      //console.log('Si parte - openApiRicercaPerRagioneSociale()')
      //console.log(this.campiformOpenApiRicercaRagioneSociale)

      this.action_press_openApiRicercaPerRagioneSociale = true

      this.$http.post('api/crm/customer/openapi/ricerca/ragionisociali', 
        this.campiformOpenApiRicercaRagioneSociale 
        ).then(response => { 
          //console.log("risposta server --------")
          //console.log(response.data)

          if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
            //risposta positiva
            this.action_press_openApiRicercaPerRagioneSociale = false;

            console.log(response.data.reply.dati.data)
            this.open_api_ricerca_items = response.data.reply.dati.data;
            
          } else {
            //risposta negativa (errore sul server)
            this.$swal({
                icon: 'error',
                title: 'Si è verificato un errore',
                text: response.data.reply.message,
                customClass: {
                confirmButton: 'btn btn-outline-primary',
                },
            })

            this.action_press_openApiRicercaPerRagioneSociale = false;
          }
        }).catch(e => {
          console.log(e);
        });
    },
    resetCampiRicercaOpenApiTutti(){
      this.campiformOpenApiRicercaPerPiva = {
        piva: '',
        id_riga: '',
      }
      this.campiformOpenApiRicercaRagioneSociale = {
        rag_sociale: '',
        id_riga: '',
      }

      //eventuale ricerca ragioni sociali
      this.open_api_ricerca_items = '';
    },
    pippo(id) {
      // Implementa la tua funzione qui
      console.log('ID:', id);
      console.log(this.campiformOpenApiRicercaRagioneSociale)

      this.isLoadingRecuperoDati = true;

      this.$http.post('api/crm/customer/openapi/ricerca/recuperadati', 
          { 
            piva: id, //codice openapi per il recupero dei dati dell'azienda
            id_riga: this.campiformOpenApiRicercaRagioneSociale.id_riga,
            userable_type: 'Lead',
          }
        ).then(response => { 
          console.log("risposta server --------")
          console.log(response.data)

          /*
          if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
            //risposta positiva
            this.isLoadingRecuperoDati = false;

            //esito negativo da OpenApi
            if(response.data.reply.status == 'errore'){
              //notifica errore
              this.$swal({
                  icon: 'error',
                  title: 'Si è verificato un errore',
                  text: response.data.reply.messaggio,
                  customClass: {
                  confirmButton: 'btn btn-danger',
                  },
              })

            } else {
              //esito ok da OpenApi
              this.$router.replace('/crm/leads/show/'+this.campiformOpenApiRicercaRagioneSociale.id_riga)
              .then(() => {
                this.$swal({
                    icon: 'success',
                    title: 'Dati sociatari e bilanci aggiornati',
                    confirmButtonText: 'chiudi',
                    customClass: {
                    confirmButton: 'btn btn-success',
                    },
                })
              })

            }
            
          }
          */

          if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
            //risposta positiva dal server
            this.isLoadingRecuperoDati = false;

            if(response.data.reply.controllo_piva_presente === true){
              //Risposta dal controllo della P.Iva già presente su un cliente
              this.controllo_piva_presente_due = true;
              this.dati_controllo_piva_due = response.data.reply;

              if(response.data.reply.deleted_at	=== null){
                //solo bottone per rimandare a scheda cliente
                this.bottone_rimandare_scheda_cliente_due = true;
                this.bottone_ripristino_scheda_cliente_due = false;
              } else {
                //bottone per ripristino cliente e poi rimandare a scheda cliente
                this.bottone_rimandare_scheda_cliente_due = false;
                this.bottone_ripristino_scheda_cliente_due = true;
              }

            } else {
              //Risposta alle operazioni di recupero dati da OpenApi

              //esito negativo da OpenApi
              if(response.data.reply.status == 'errore'){
                //notifica errore
                this.$swal({
                    icon: 'error',
                    title: 'Si è verificato un errore',
                    text: response.data.reply.messaggio,
                    customClass: {
                    confirmButton: 'btn btn-danger',
                    },
                })

              } else {
                //esito ok da OpenApi
                this.$router.replace('/crm/leads/show/'+this.campiformOpenApiRicercaRagioneSociale.id_riga)
                .then(() => {
                  this.$swal({
                      icon: 'success',
                      title: 'Dati sociatari e bilanci aggiornati',
                      confirmButtonText: 'chiudi',
                      customClass: {
                      confirmButton: 'btn btn-success',
                      },
                  })
                })

              }

            }
            
          } else {
            //risposta negativa (errore sul server)
            this.$swal({
                icon: 'error',
                title: 'Si è verificato un errore',
                text: response.data.reply.message,
                customClass: {
                confirmButton: 'btn btn-outline-primary',
                },
            })

            this.isLoadingRecuperoDati = false;
          }




        }).catch(e => {
          console.log(e);
        });

    },

  },
  formattazioneDataOra(created_at,lang) {
    if (created_at) {
      return moment(String(created_at)).locale(lang).format('DD/MM/YYYY - HH:mm:ss')
    } else {
      return '---'
    }
  },
  
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-good-table.scss';
  
  .mio_btn_float{
    display: block;
    position: absolute;
    top: -55px;
    right: 2px;
    z-index: 10;
  }

  .spacer-dettagli{
    width: 90px;
    display: inline-block;
  }

  .truncate {
    width: 86%;
    white-space: nowrap; /* Impedisce il ritorno a capo */
    overflow: hidden; /* Nasconde il testo in eccesso */
    text-overflow: ellipsis; /* Aggiunge i "..." quando il testo è troppo lungo */
  }

  .spacer-icona{
    width: 30px;
    display: inline-block;
  }
  .mb-05{
    font-weight: 500;
  }
  .assistenti-card{
    position: relative;
  }
  .puntini-nuovo{
    display: block;
    position: absolute;
    top: 20px;
    right: 22px;
    z-index: 15;
  }
  .puntini{
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
    margin-left: 15px;
    z-index: 10;
  }

  .capitalize-first-word::first-letter {
    text-transform: uppercase;
  }

  .comments-list .scroll-area {
    position: relative;
    margin: auto;
    padding: 10px;
    width: 100%;
    height: 320px;
    border: 1px solid #eee;
  }
  .comments-list .scroll-area ul li.riga{
    border-bottom: 1px solid #eee;
    padding: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    transition: all 0.2s, border-color 0s;
    position: relative;
  }
  .comments-list .scroll-area ul li.riga:hover{
    transform: translateY(-4px);
    box-shadow: 0 3px 10px 0 #ebe9f1;
  }
  .comments-list .scroll-area ul li.riga:last-child{
    border-bottom: none;
  }
  .comments-list .scroll-area ul li.riga .dx{
    min-width: 150px;
    margin-left: 25px;
    text-align: right;
  }

  .selezione_licenza{
    margin-top: 25px;
  }
  .selezione_licenza .custom-radio.custom-control{
    margin-bottom: 15px;
    padding-bottom: 14px;
    border-bottom: 1px solid #ebe9f1;
  }
  .selezione_licenza .custom-radio.custom-control .custom-control-label{
    width: 100%;
  }

  .spacer-dettagli{
    width: 90px;
    display: inline-block;
  }

  .logs-colonna{
    background: #fafafa;
    padding: .4rem .8rem;
    font-size: 0.85rem;
    color: #d81b60;
    word-break: break-word;
    min-height: 130px;
  }  

  .swiper-pagination {
    position: inherit !important;
    padding: 0px 15px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .swiper-pagination-clickable .swiper-pagination-bullet {
    margin-right: 10px;
  }
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
  }
  .swiper-pagination-bullet-active {
    background: #009688 !important;
  }
</style> 
